const locale = process.env.VUE_APP_LOCALE || "de";
const routePaths = {
  startseite: {
    de: "/",
    en: "/"
  },
  teamErweitern: {
    de: "/team-erweitern",
    en: "/extend-your-team"
  },
  projektStarten: {
    de: "/projekt-starten",
    en: "/start-a-project"
  },
  impressum: {
    de: "/impressum",
    en: "/imprint"
  },
  datenschutz: {
    de: "/datenschutz",
    en: "/privacy"
  },
  mf3d: {
    de: "/virtual-reality",
    en: "/virtual-reality"
  },
  "story-digitalisierung-im-handwerk": {
    de: "/stories/digitalisierung-im-handwerk",
    en: "/stories/digital-apps-for-trades"
  },
  "story-digitale-prozessoptimierung-fuer-energieversorger": {
    de: "/stories/digitale-prozessoptimierung-fuer-energieversorger",
    en: "/stories/process-optimization-for-energy-supplier"
  },
  "story-mit-virtual-reality-zur-bootsmesse": {
    de: "/stories/mit-virtual-reality-zur-bootsmesse",
    en: "/stories/virtual-reality-houseboat-trade-fair"
  }
};

const paths = Object.values(routePaths).map(el => el[locale]);
const namedRoutes = {};
Object.entries(routePaths).forEach(([key, value]) => {
  namedRoutes[key] = value[locale];
});

module.exports = { namedRoutes, paths };
