<template>
  <a
    class="menu-icon d-block position-relative"
    :class="isOpen && 'open'"
    @click="buttonAction"
  >
    <span class="position-absolute d-block w-100" :style="buttonColor" />
    <span class="position-absolute d-block w-100" :style="buttonColor" />
    <span class="position-absolute d-block w-100" :style="buttonColor" />
  </a>
</template>
<script>
import { layoutState } from "@/components/layouts/layoutStyles";

export default {
  name: "MainMenuButton",
  computed: {
    isOpen() {
      return this.isOverlay;
    },
    buttonColor() {
      return layoutState.layoutStyle.mainMenuIcon;
    },
    isOverlay: {
      get() {
        return layoutState.showMainMenuOverlay;
      },
      set(value) {
        layoutState.showMainMenuOverlay = value;
      }
    }
  },
  methods: {
    buttonAction() {
      if (this.isOverlay) {
        this.isOverlay = false;
        this.unwatch();
      } else {
        this.isOverlay = true;
        this.unwatch = this.$watch("$route", () => {
          this.isOverlay = false;
          this.unwatch();
        });
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@import "../../../styles/imports";

.menu-icon {
  width: 35px;
  height: 18px;
  cursor: pointer;
  opacity: 0.6;
  z-index: 1;

  &:hover {
    opacity: 1;
  }

  > span {
    max-width: 35px;
    border-radius: 3px;
    height: 2px;
    transition: transform 0.3s ease-in-out;
    top: 0;

    &:nth-child(2) {
      transform: translateY(9px);
    }

    &:nth-child(3) {
      transform: translateY(18px);
    }
  }

  &.open {
    display: block;
    margin-right: 0px;

    span:nth-child(1) {
      transform: translateY(9px) rotate(45deg);
    }

    span:nth-child(2) {
      transform: translateX(100vw);
    }

    span:nth-child(3) {
      transform: translateY(9px) rotate(-45deg);
    }
  }
}
</style>
