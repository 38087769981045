import VueI18n from "vue-i18n";
import Vue from "vue";

Vue.use(VueI18n);

const currentLocale = process.env.VUE_APP_LOCALE || "de";
const messages = {
  // for often used messages
  de: {
    slogan: {
      digitalInnovation: "Digitale Innovation leicht gemacht"
    }
  },
  en: {
    slogan: {
      digitalInnovation: "Digital innovation made easy"
    }
  }
};

const i18n = new VueI18n({
  locale: currentLocale,
  fallbackLocale: "de",
  silentFallbackWarn: true,
  messages
});

export { i18n };
