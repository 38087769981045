import Vue from "vue";
import VeeValidate, { Validator } from "vee-validate";
import de from "vee-validate/dist/locale/de";
import VueMeta from "vue-meta";
import VueMatomo from "vue-matomo";
import smoothscroll from "smoothscroll-polyfill";
import { i18n } from "@/i18n";

import App from "./App.vue";
import { router } from "./router/router";

smoothscroll.polyfill();
Vue.use(VeeValidate);
Validator.localize("de", de);
Vue.config.productionTip = false;

Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: false
});

if (process.env.VUE_APP_SITE_ID) {
  Vue.use(VueMatomo, {
    host: "https://analytics.mediaformat.de",
    siteId: process.env.VUE_APP_SITE_ID,
    router,
    enableLinkTracking: true,
    requireConsent: false,
    trackInitialView: true,
    disableCookies: true,
    enableHeartBeatTimer: true,
    heartBeatTimerInterval: 15,
    debug: false,
    userId: undefined,
    cookieDomain: undefined,
    domains: undefined,
    preInitActions: []
  });
}

new Vue({
  $_veeValidate: {
    events: ""
  },
  router,
  i18n,
  render: h => h(App),
  mounted: () => document.dispatchEvent(new Event("x-app-rendered"))
}).$mount("#app");
