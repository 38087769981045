import Vue from "vue";

export const dark = () => ({
  backgroundLayout: "#151b1f",
  backgroundFooter: "#05a4ff",
  backgroundFooterLine: "#46b3ff",
  color: "#fff",
  line: "#333539",
  backgroundHeader: "#151b1f",
  footerLogoColor: "#fff",
  mainMenuIcon: {
    backgroundColor: "#fff"
  }
});

export const light = () => ({
  backgroundLayout: "#ffffff",
  backgroundFooter: "#ffffff",
  backgroundFooterLine: "#eaeaeb",
  color: "#56717c",
  line: "#eaeaeb",
  backgroundHeader: "#ffffff",
  footerLogoColor: "#04A4FF",
  mainMenuIcon: {
    backgroundColor: "#000",
    opacity: 0.5
  }
});

export const layoutState = new Vue({
  data() {
    return {
      theme: "light",
      showPhone: false,
      hideHeader: false,
      logo: null,
      slogan: null,
      headStyle: {},
      isSubPage: true,
      overruledTheme: {},
      showMainMenuOverlay: false,
      routeTransition: false
    };
  },
  computed: {
    isLightTheme() {
      return this.theme === "light";
    },
    layoutStyle() {
      const theme = this.isLightTheme ? light() : dark();

      return {
        ...theme,
        ...this.overruledTheme
      };
    }
  },
  methods: {
    setPageConfig(theme = "light", isSubPage = true) {
      this.theme = theme;
      this.isSubPage = isSubPage;
      this.hideHeader = false;
      this.slogan = null;
      this.logo = null;
      this.showPhone = false;
      this.overruleTheme({});
      document.body.style.backgroundColor = this.layoutStyle.backgroundLayout;
    },
    setLogo(is, props = {}, events = {}) {
      this.logo = {
        is,
        props,
        events
      };
    },
    overruleTheme(obj = {}) {
      this.overruledTheme = obj;
    }
  }
});
