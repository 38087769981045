<template>
  <transition name="fade">
    <section
      class="menu-overlay position-fixed d-flex flex-column align-items-center"
      v-if="layoutState.showMainMenuOverlay"
      :style="overlayStyle"
    >
      <VerticalLines />
      <div class="container">
        <div class="item-wrapper">
          <ul class="menu-list">
            <li v-for="route in routes" :key="route.name" class="menu-item">
              <router-link class="menu-item-link" :to="{ name: route.name }">
                {{ $t(route.text) }}
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </section>
  </transition>
</template>

<script>
import { layoutState } from "@/components/layouts/layoutStyles";
import VerticalLines from "@/components/layouts/VerticalLines";

import { disableScroll, enableScroll } from "@/utils/scroll";

const routes = [
  {
    name: "startseite",
    text: "home"
  },
  {
    name: "teamErweitern",
    text: "extendTeam"
  },
  {
    name: "projektStarten",
    text: "startAProject"
  },
  {
    name: "mf3d",
    text: "vr3d"
  }
];

export default {
  name: "MainMenuOverlay",
  components: { VerticalLines },
  data() {
    return {
      routes,
      layoutState
    };
  },
  computed: {
    overlayStyle() {
      return {
        backgroundColor: this.layoutState.layoutStyle.backgroundLayout
      };
    }
  },
  mounted() {
    disableScroll();
  },
  destroyed() {
    enableScroll();
  },
  i18n: {
    messages: {
      de: {
        home: "Startseite",
        extendTeam: "Team erweitern",
        startAProject: "Projekt starten",
        vr3d: "VR/Web-3D"
      },
      en: {
        home: "Home",
        extendTeam: "Extend your team",
        startAProject: "Start a project",
        vr3d: "VR/3D Web"
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../../styles/imports";
$main-color: #05a4ff;
$second-color: #56717c;
.item-wrapper {
  margin: 0 25%;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease-in-out;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.menu-overlay {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  justify-content: center;
  background-color: $body-bg-dark;
  padding-top: 45px;
  margin-left: -6px;
}

.menu-list {
  margin-left: -40px;
  list-style: none;
  z-index: 2;
}

.menu-item {
  font-size: $font-size-xl;
  font-weight: $font-weight-bold;
  line-height: 73px;
}
@media screen and (max-width: 411px) {
  .menu-item {
    font-size: $font-size-md;
    line-height: 60px;
  }
  .menu-list {
    margin-left: -45px;
  }
}
@media screen and (max-width: 992px) {
  .item-wrapper {
    margin: 0 0 0 5px;
  }
}
.menu-item-link {
  cursor: pointer;
  text-decoration: none;
  color: $main-color;
  transition: color 0.2s ease-in-out;

  &:hover,
  &.router-link-exact-active {
    color: $hover-btn;
  }
}
@media (max-width: 813px) and (orientation: landscape) {
  .menu-item-link {
    font-size: $font-size-md;
  }
  .menu-item {
    line-height: 43px;
  }
}
</style>
