import Vue from "vue";
import Router from "vue-router";
import { layoutState } from "@/components/layouts/layoutStyles";
import routePaths from "./routePaths";

function loadRoute(routeName) {
  return () => import(`@/views/${routeName}`);
}

Vue.use(Router);

const namedRoutes = [
  {
    name: "startseite",
    component: loadRoute("Startseite")
  },
  {
    name: "teamErweitern",
    component: loadRoute("TeamErweitern")
  },
  {
    name: "projektStarten",
    component: loadRoute("ProjektStarten")
  },
  {
    name: "impressum",
    component: loadRoute("Impressum")
  },
  {
    name: "datenschutz",
    component: loadRoute("Datenschutz")
  },
  {
    name: "mf3d",
    component: loadRoute("MF3D")
  },
  {
    name: "story-digitalisierung-im-handwerk",
    component: loadRoute("StoryDigitalisierungImHandwerk")
  },
  {
    name: "story-digitale-prozessoptimierung-fuer-energieversorger",
    component: loadRoute("StoryDigitaleProzessoptimierungFuerEnergieversorger")
  },
  {
    name: "story-mit-virtual-reality-zur-bootsmesse",
    component: loadRoute("StoryMitVRZurBootsmesse")
  }
].map(el => {
  return {
    ...el,
    path: routePaths.namedRoutes[el.name]
  };
});

const routes = [
  ...namedRoutes,
  {
    path: "/s/eh",
    redirect: { name: "story-digitalisierung-im-handwerk" }
  },
  {
    path: "/s/en",
    redirect: {
      name: "story-digitale-prozessoptimierung-fuer-energieversorger"
    }
  },
  {
    path: "/s/vr",
    redirect: { name: "story-mit-virtual-reality-zur-bootsmesse" }
  },
  {
    path: "*",
    redirect: { name: "startseite" }
  }
];

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    const position = {
      x: 0,
      y: 0,
      ...savedPosition
    };
    return new Promise(resolve => {
      this.app.$root.$once("scrollAfterEnter", () => {
        resolve(position);
      });
    });
  },
  routes: [...routes]
});

router.beforeEach((from, to, next) => {
  layoutState.routeTransition = true;
  setTimeout(() => {
    next();
  }, 300);
});

router.afterEach(() => {
  setTimeout(() => {
    layoutState.routeTransition = false;
  }, 1000);
});

export { router, routes };
