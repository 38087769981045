<template>
  <footer
    class="position-relative"
    :style="{ backgroundColor: theme.backgroundFooter }"
  >
    <VerticalLines style="bottom: 0;" :color="theme.backgroundFooterLine" />
    <div class="container">
      <div class="flex-row">
        <div class="footer-mobile text-center">
          <router-link
            :style="`color: ${theme.color}`"
            class="footer-link"
            :to="{ name: 'impressum' }"
          >
            {{ $t("imprint") }}
          </router-link>
          <router-link
            :style="`color: ${theme.color}`"
            class="footer-link"
            :to="{ name: 'datenschutz' }"
          >
            {{ $t("privacy") }}
          </router-link>
        </div>
        <div class="spacer" />
        <router-link
          class="footer-link mobile-hidden"
          :to="{ name: 'startseite' }"
        >
          <mf-logo
            class="footer-img mobile-hidden"
            :color="theme.footerLogoColor"
          />
        </router-link>
        <a class="footer-link" @click="slideUp">
          <img
            class="go-up position-absolute"
            src="@/assets/img/layout/arrow_down_white.svg"
            alt="MEDIAFORMAT GmbH Logo"
          />
        </a>
      </div>
    </div>
  </footer>
</template>

<script>
import { layoutState } from "@/components/layouts/layoutStyles";
import MfLogo from "@/components/logos/MfLogo";
import VerticalLines from "@/components/layouts/VerticalLines";

export default {
  name: "LayoutFooter",
  components: { VerticalLines, MfLogo },

  computed: {
    theme() {
      return layoutState.layoutStyle;
    }
  },
  methods: {
    slideUp() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth" // not supported by safari
      });
    }
  },
  i18n: {
    messages: {
      de: {
        imprint: "Impressum",
        privacy: "Datenschutz"
      },
      en: {
        imprint: "Imprint",
        privacy: "Privacy"
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../styles/imports";

$footerHeight: 65px;
$footerFontSize: 0.75rem;

.mobile-hidden {
  display: none;
  @include breakpoint(md) {
    display: block;
  }
}
.footer-mobile {
  width: 100%;
  @include breakpoint(md) {
    width: auto;
  }
}

footer {
  padding: $spacer / 4 0;
  height: $footerHeight;

  .footer-link {
    font-size: $footerFontSize;
    color: $text-color-dark;
    text-transform: uppercase;
    transition: opacity 0.2s ease-in-out;

    &:hover {
      opacity: 0.7;
    }

    + .footer-link {
      margin: 0 0 0 12px;
    }
  }

  .footer-img {
    width: 170px;
    margin-right: -11px;
  }

  .go-up {
    display: none;
    transform: rotate(180deg);
    left: 49.5%;
    margin: 0 auto;
    max-width: 15px;
    max-height: 9px;
    cursor: pointer;
    transition: opacity 0.2s ease-in-out;
    @include breakpoint(md) {
      display: block;
    }
  }
}
</style>
