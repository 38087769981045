<template>
  <Layout id="app">
    <transition name="fade" mode="out-in" @before-enter="afterEnter">
      <router-view :key="$route.name" />
    </transition>
  </Layout>
</template>

<script>
import Layout from "@/components/layouts/Layout";

export default {
  name: "App",
  metaInfo() {
    return {
      title: this.$t("slogan.digitalInnovation"),
      titleTemplate: "%s · MEDIAFORMAT GmbH",
      htmlAttrs: {
        lang: process.env.VUE_APP_LOCALE || "de"
      }
    };
  },
  components: { Layout },
  methods: {
    afterEnter() {
      this.$root.$emit("scrollAfterEnter");
    }
  }
};
</script>

<style lang="scss" src="@/styles/base.scss"></style>
<style lang="scss">
@import "styles/_imports.scss";
// Finished refactoring Mediaformat.
*::-webkit-scrollbar-track {
  background-color: $colorInputLight;
}

::selection {
  background: $accent;
  color: $body-bg-dark;
}
::-moz-selection {
  background: $accent;
  color: $body-bg-dark;
}
::-webkit-selection {
  background: $accent;
  color: $body-bg-dark;
}

* {
  scrollbar-color: $primary $colorInputLight;
  scrollbar-width: thin;
}

*::-webkit-scrollbar {
  width: 6px;
  background-color: $colorInputLight;
}

*::-webkit-scrollbar-thumb {
  background-color: $primary;
  border-radius: 25px;
}

button {
  outline: none;
}

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

.hideScroll {
  overflow-y: hidden;
}
</style>
